import '../css-build/main.css';
import { Elm } from '../elm/Main.elm';
import { providerType, langFromURL } from 'compound-components/src/js/sharedEth/utils';
import TagManager from 'react-gtm-module';

var oldErrorHandler = window.onerror || function () {};

// Initialize google tag manager id based on environment
var tagManagerArgs = {
  // dev
  gtmId: process.env.GTM_ID,
  events: {
    pageView: 'PageView',
    logIn: 'LogIn',
    logOut: 'LogOut',
    setOrganization: 'SetOrganization',
  },
};

// default to undefined for user's organization
var organizationTagManagerArgs = undefined;

TagManager.initialize(tagManagerArgs);

window.onerror = function (message, source, lineno, colno, error) {
  // handleError(message, source, lineno, colno, error);
  oldErrorHandler(message, source, lineno, colno, error);
  return true;
};

window.addEventListener('load', function () {
  const url = new URL(window.location);

  let buildHref = (href) => href;
  let stripHref = (href) => href;
  let mountPath = window.BUILD_MOUNT_PATH || process.env.MOUNT_PATH || '';
  if (mountPath) {
    let mountRegex = new RegExp(`^/${mountPath}`);

    stripHref = (href) => {
      let url = new URL(href);
      url.pathname = url.pathname.replace(mountRegex, '');
      return url.href;
    };

    buildHref = (href) => {
      let url = new URL(href);
      url.pathname = mountPath + url.pathname;
      return url.href;
    };
  }

  const app = Elm.Main.init({
    node: document.getElementById('root') || document.getElementById('main'), // no pre-rendered // pre-rendered
    flags: {
      language: langFromURL(url, window.navigator.language),
      path: stripHref(window.location.href),
      userAgent: navigator.userAgent,
      token: sessionStorage.getItem('treasurySession'),
      apiBaseUrl: process.env.API_BASE_URL,
      minWithdrawalDollars: process.env.MIN_WITHDRAWAL_DOLLARS,
    },
  });

  function findParent(tagname, el) {
    if (!el) {
      return null;
    }

    if ((el.nodeName || el.tagName).toLowerCase() === tagname.toLowerCase()) {
      return el;
    }

    return findParent(tagname, el.parentNode);
  }

  document.addEventListener('click', (e) => {
    e = e || event;
    var from = findParent('a', e.target || e.srcElement);
    if (from && from.href && !from.rel && !from.href.startsWith('blob:')) {
      history.pushState({}, '', buildHref(from.href));
      e.preventDefault();
      app.ports.onUrlChange.send(from.href);
    }
  });

  // Inform app of browser navigation (the BACK and FORWARD buttons)
  let statePopped = false;
  window.addEventListener('popstate', () => {
    statePopped = true;
    app.ports.onUrlChange.send(location.href);
  });

  app.ports.log.subscribe((msg) => {
    console.error(msg);
  });

  app.ports.scrollIntoView.subscribe((elementId) => {
    let element = document.getElementById(elementId);

    if (element) {
      const top = element.offsetTop;
      window.scrollTo(0, top + 24);
    }
  });

  app.ports.scrollTop.subscribe(() => {
    if (!statePopped) {
      window.scrollTo(0, 0);
    } else {
      statePopped = false;
    }
  });

  let postRender = window.requestAnimationFrame;

  app.ports.highlightScroll.subscribe(([navLinkSelector, sectionSelector]) => {
    postRender(() => {
      highlightScroll(navLinkSelector, sectionSelector);
    });
  });

  app.ports.setTitle.subscribe((title) => {
    document.title = title;
  });

  app.ports.setUserOrganization.subscribe((org) => {
    if (org && org !== '') {
      organizationTagManagerArgs = org;
      TagManager.dataLayer({
        dataLayer: {
          event: 'setOrganization',
          organization: org,
        },
      });
    } else {
      organizationTagManagerArgs = undefined;
    }
  });

  app.ports.sendGTMSignal.subscribe((userEmail) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageView',
        organization: organizationTagManagerArgs,
        user: userEmail,
      },
    });
  });

  app.ports.sendLogInGTMSignal.subscribe((userEmail) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'logIn',
        organization: organizationTagManagerArgs,
        user: userEmail,
      },
    });
  });

  app.ports.sendLogOutGTMSignal.subscribe((userEmail) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'logOut',
        organization: organizationTagManagerArgs,
        user: userEmail,
      },
    });
  });

  app.ports.redirectTo.subscribe((href) => {
    let url = new URL(location.href);
    url.pathname = href;
    url.hash = '';
    let urlString = url.toString();
    history.pushState({}, document.title, urlString);
    app.ports.onUrlChange.send(urlString);
  });

  app.ports.setToken.subscribe((token) => {
    let tokenKey = 'treasurySession';

    if (token !== null) {
      sessionStorage.setItem(tokenKey, token);
    } else {
      sessionStorage.removeItem(tokenKey);
    }
  });

  app.ports.copy.subscribe((text) => {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  });

  app.ports.downloadCSV.subscribe(([name, data]) => {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    let blob = new Blob([data], { type: 'octet/stream' });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
  });

  app.ports.handleImageError.subscribe(({ elementId, imgSrc }) => {
    const element = document.getElementById(elementId);

    if (element) {
      console.warn('Image could not be loaded: ', element);

      element.src = imgSrc;
    }
  });

  app.ports.withdrawModalWatch.subscribe((text) => {
    var fnf = document.getElementById('action-input-box');
    var fnfInvisible = document.getElementById('action-input-box--invisible');

    if (fnf != null) {
      fnf.addEventListener(
        'input',
        function (evt) {
          if (this.value == '$') {
            fnf.value = '';
          } else if (this.value.length > 0) {
            var valueBefore = this.value;
            var dotSeparator = '.';

            const indexOfDotSeparator = valueBefore.indexOf(dotSeparator);
            var wholeNumberString = valueBefore;
            if (indexOfDotSeparator > 0) {
              var wholeNumberString = valueBefore.substring(0, indexOfDotSeparator);
            }

            var wholeNumberPart = parseInt(wholeNumberString.replace(/\D/g, ''), 10) || 0;

            // Next we should try to verify the input of the fractional portion which
            // is a little more tricky.
            var decimalsSuffix = '';
            if (indexOfDotSeparator > 0 && indexOfDotSeparator < valueBefore.length - 1) {
              // If the user types in a fraction amount then limit to 2 characters.
              const decimalsString = valueBefore.substring(indexOfDotSeparator, indexOfDotSeparator + 3);

              // Super weird, but when validating the fraction amount, we should preserve
              // leading 0s...
              const decimalsAsInt = parseInt(decimalsString.replace(/\D/g, ''), 10) || 0;

              // We gotta handle the case where the user types in `X.0` and remember to NOT inject a leading 0
              // in the case, otherwise we can
              if (decimalsString.startsWith(dotSeparator + '0') && !decimalsString.endsWith(dotSeparator + '0')) {
                decimalsSuffix = dotSeparator + '0' + decimalsAsInt;
              } else {
                decimalsSuffix = dotSeparator + decimalsAsInt;
              }
            } else if (valueBefore.endsWith(dotSeparator)) {
              decimalsSuffix = dotSeparator;
            }

            var prefix = '$';
            var value = wholeNumberPart.toLocaleString('en') + decimalsSuffix;

            if (fnfInvisible !== null) {
              fnfInvisible.textContent = value;
              // remove prefix
              prefix = '';
            }

            fnf.value = prefix + value;
          }
        },
        false
      );
    }
  });
});
